@keyframes sparkle {
    0% {
      background-position: 0% 50%;
      opacity: 0;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      background-position: 100% 50%;
      opacity: 0;
    }
  }
  
  .sparkle-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(153, 102, 204, 0.2);
    z-index: 50;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .sparkle {
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #9966CC, #BA55D3, #9966CC);
    background-size: 200% 200%;
    animation: sparkle 2s linear infinite;
    opacity: 0.5;
    mix-blend-mode: overlay;  /* 배경과 블렌딩 */
  }